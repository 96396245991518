import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import content from '../../content/courses-gi'
import ContentBlock from './Content'
import giImage from '../../../assets/bdjj-067.jpg'
import SlideIn from '../../utils/SlideIn'

export default function Gi () {
  return (
    <Box
      id="gi"
      className="marginContainerTall creme"
    >
      <SlideIn>
        <Container maxWidth="xl" disableGutters={true}>
          <ContentBlock
            image={giImage}
            imageAlt="Training in the gi"
            title="Gi Jiu-Jitsu"
            content={content}
            subtitle="Brazilian Jiu-Jitsu"
          />
        </Container>
      </SlideIn>
    </Box>
  )
}
