import * as React from 'react'
import Box from '@mui/material/Box'
import ReactMarkdown from 'react-markdown'

import Image2 from '../../../assets/kneeonbelly2.jpg'
import courses from '../../content/courses'
import TwoColumnHero from '../../TwoColumnHero'

export default function Hero () {
  return (
    <TwoColumnHero
      title="Courses"
      subtitle="Gi and No-Gi Jiu-Jitsu"
      image={Image2}
      dimmedImage={true}
    >
      <Box
        sx={{
          color: '#ffffff',
          marginTop: '-1em'
        }}
      >
        <ReactMarkdown>{courses}</ReactMarkdown>
      </Box>
    </TwoColumnHero>
  )
}
