import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export default function Commitment () {
  return (
    <Box id="commitment" className="creme">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className="marginContainer">
            <Typography variant="h5" mb={1} sx={{ textTransform: 'uppercase' }}>
              Commitment to Young Athletes
            </Typography>
            <Typography variant="body1" mb={1}>
              Jiu-Jitsu can be one of those things in people's lives that brings them enormous satisfaction. From experience, we know that many people spend the better part of their week day-dreaming about their next, incredible open mat or training session.
            </Typography>
            <Typography variant="body1" mb={1}>
              We also recognize that Young Athletes need to achieve minimum standards of academic excellence before spending time on the mats. We strongly, strongly support our Young Athletes exceptional performance at school just as much as we recognize exceptional performance on the mats.  Life is just starting for these dudes, and a great showing at school is a so important as they move forward in life.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
