import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
// import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
// import useMediaQuery from '@mui/material/useMediaQuery'
import Link from '@mui/material/Link'
import data from '../../../data'

export default function SubHero () {
  return (
    <Box id="commitment" className="creme">
      <Grid container>
        <Grid item xs={12}>
          <Box className="marginContainer">
          <Typography
            mb={2}
            color="secondary.dark"
            sx={{
              textTransform: 'uppercase',
              fontWeight: '700',
              lineHeight: {
                xs: 0.9,
                md: 0.85
              },
              fontSize: {
                xs: '40px',
                sm: '90px',
                md: '150px'
              },
              letterSpacing: {
                xs: '0px',
                md: '-10px'
              }
            }}>Jiu-Jitsu can be a medium for success far beyond the gym.</Typography>
            <Link href={data.memberPortal.yaEnrollmentUrl} color="secondary.dark"><Typography variant="body1" mb={1}>Join the Young Athletes team at The Black Dog Jiu-Jitsu Company.</Typography></Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
