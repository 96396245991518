import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ReactMarkdown from 'react-markdown'

import SlideIn from '../../utils/SlideIn'
import Signature from '../../svgs/Signature'
import Tim from '../../../assets/bdjj-018.jpg'
import about from '../../content/about'

import FullLeftHero from '../../FullLeftHero'

export default function AboutFullDesktop () {
  return (
    <Box className="creme">
      <FullLeftHero
        id="about"
        imageAlt="Tim"
        image={Tim}
      >
        <SlideIn>
          <Typography variant="h5" mb={2} sx={{ fontSize: '24px' }}>
            We created The Black Dog Jiu-Jitsu Company to train the way that we’ve always felt was most rewarding.
          </Typography>
          <ReactMarkdown>
            {about}
          </ReactMarkdown>
          <Box sx={{ textAlign: 'left', width: '100%' }} mt={4}>
            <Signature fill="#111" width="40%" />
          </Box>
        </SlideIn>
      </FullLeftHero>
    </Box>
  )
}
