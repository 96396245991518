/* eslint-disable */
import data from '../../data'
const content = `
Young athletes excel at The Black Dog Jiu-Jitsu Company.

We lead with fun, not pedagogy.  There's not a lot of formality, kids are free to be themselves and grow at what rate best suits them.  Naturally, over time significant growth is shown both in terms of maturity on and off the mats as well as athletic potential.  

Some kids show up already very physical and focused while others start with a sense of reservation or shyness.  We're here for all of it.  We believe jiu-jitsu can be a critical bridge for young people to grown and demonstrate their potential to the world. 

Athletes that enroll in the program benefit from the diverse group of individuals that constitute our Young Adult team as well as an extended community of youth grapplers;  Off-season wrestlers, cross-training Judo competitors and athletes at other local academies regularly drop in to sharpen their skills.

Young athletes are the future of our sport.  At The Black Dog we welcome your potential, your hard work, good humor and well earned accomplishments.  Come train.
`;

export default content
