import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import Image2 from '../../../assets/kneeonbelly2.jpg'
import TwoColumnHero from '../../TwoColumnHero'

export default function Hero () {
  return (
    <TwoColumnHero
      title="Martial Arts"
      subtitle="And Combat Sport"
      image={Image2}
      dimmedImage={true}
    >
      <Box
        sx={{
          color: '#ffffff',
          marginTop: '-1em'
        }}
      >
        <Box>
            <Typography>Disclaimer:  We had to write this article so that we'd show up in search results for the term "Martial Arts" - so please feel free to get right on out of here.  But if you insist on reading further, the jist of the lengthy, boring docment is this: Outisde of the obnoxious cultural associations, we don't mind the term "Martial Arts".  Mostly.</Typography>
        </Box>
      </Box>
    </TwoColumnHero>
  )
}
