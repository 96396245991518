import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import TempYA1 from '../../../assets/teens1.jpg'
import TempYA2 from '../../../assets/teens2.jpg'
import TempYA3 from '../../../assets/teens3.jpg'
import SlideIn from '../../utils/SlideIn'

function CommitmentPrincipal (props: any) {
  const backgroundImage = {
    backgroundImage: `url(${props.image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'grayscale(100%)'
  }
  const reverse = (props.reverse) ? 'row-reverse' : 'row'
  return (
    <Box>
      <Grid container direction={reverse}>
        <Grid item xs={12} md={6}>
          <Box
            className="dimmedImage"
            sx={{
              width: { xs: '100vw', md: '50vw' },
              height: { xs: '40vh', md: '50vw' },
              ...backgroundImage
            }}
          >
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            className="marginContainer"
            sx={{
              height: '100%',
              width: '100%'
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{ width: '100%' }}
              >
                <Typography
                  variant="h3"
                  color="primary"
                  mb={1}
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: { xs: '1.5em', md: '2.5em' }
                  }}
                >
                  { props.title }
                </Typography>
                { props.children }
              </Box>
          </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default function Commitment () {
  const smallScreen = !useMediaQuery('(min-width:600px)')
  return (
    <Box id="commitment" className="white">
      <SlideIn>
        <Grid container>
          <Grid item xs={12}>
            <CommitmentPrincipal
              title="Authenticity"
              image={TempYA3}
            >
              <Typography variant="body1" mb={1}>
                We’re committed to teaching/training only what is demonstrably relevant and effective.  Our curriculum is based exclusively on the state of the art in Brazilian Jiu-Jitsu, Submission Grappling, Judo, and Wrestling - but for kids.  What's that mean?  You don't need to get it right the first time, the 10th time or the 1000th time - we know that eventually these athletes get it right.
              </Typography>
            </CommitmentPrincipal>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                textAlign: (smallScreen) ? 'left' : 'right'
              }}
            >
              <CommitmentPrincipal
                title="Accountability"
                image={TempYA1}
                reverse={!smallScreen}
              >
                <Typography
                  variant="body1"
                  mb={1}
                >
                  Accountability is making sure that when your victories are earned, someone celebrates that success with you.  Positive reinforcement is a powerful tool to help kids realize their potential and develop a strong sense of self worth to present back to the world.
                </Typography>
              </CommitmentPrincipal>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CommitmentPrincipal
              title="Growth"
              image={TempYA2}
            >
              <Typography variant="body1" mb={1}>
                If you're willing put in the work, we'll make sure you see results.  Building great habits on the mats translates into great habits off the mats.  And that's a magical thing.
              </Typography>
            </CommitmentPrincipal>
          </Grid>
        </Grid>
      </SlideIn>
    </Box>
  )
}
