import * as React from 'react'
import Box from '@mui/material/Box'
import ReactMarkdown from 'react-markdown'

import TwoColumnHero from '../../TwoColumnHero'
import Image2 from '../../../assets/IMG_3276.jpg'
import ya from '../../content/ya'
import data from '../../../data'

export default function Hero () {
  return (
    <TwoColumnHero
      title="Young Adults"
      subtitle={`Athletes aged ${data.ya.age.min} - ${data.ya.age.max}`}
      image={Image2}
      dimmedImage={true}
    >
      <Box sx={{
        color: '#ffffff',
        marginTop: '-1em'
      }}
      >
        <ReactMarkdown>{ya}</ReactMarkdown>
      </Box>
    </TwoColumnHero>
  )
}
